<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right" separator="/">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item>平台介绍</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">平台概述</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">杨凌农业人力资源共享服务开放平台
             <p class="updateTime">更新时间：2022-10-14</p>
          </div>
          <hr style="height:1px;border:none;border-top:1px solid #D3DCE6;" /> 
          <article>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杨凌农业人力资源共享服务开放平台（数据共享交换系统）是完成信息交换与整合功能的系统，可实现多个独立应用或是与省级部门之间的横向数据交换功能，消除信息孤岛；在提供横向数据交换的同时，逐步</p>
            <p>积累数据，为更深层次的数据应用（数据仓库与智能分析）提供数据基础，同时，为杨凌农业人力资源共享服务平台建设提供各类服务接口，为平台数据对接和交换提供便利和安全通道，实现数据的安全、高效的传输和应用。</p>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数据共享交换系统是完成信息交换与整合功能的系统，可实现多个独立应用或是与省级部门之间的横向数据交换功能，消除信息孤岛；在提供横向数据交换的同时，逐步积累数据，为更深层次的数据应用（数据仓库与智能分析）提供数据基础。</p>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“杨凌农业人力资源共享服务平台”项目建设的主要目标是：一个集农业人力资源数据整合、加工、处理、整合建库、综合分析、应用分发、可视化展示等于一体，涉及面广，技术先进，实用性强，用户分布广的大型应用平台，通过平台的建设不仅可满足农业人力资源共享服务的要求，同时可服务于政府部门监管、资源调配、数据展示和机构服务流程的数字化提升。
              杨凌农业人力资源共享服务平台建设内容分为：共享农业人力资源平台支撑系统、数据共享交换系统、平台标准规范建设、农业人力资源共享服务应用系统、农业人力资源共享数据分析系统、数据可视化系统等5个部分。
              支撑系统作为整个平台的调度入口和总线服务部分，为整个平台提供统一的用户认证、运营调度和运维管理等功能，同时为整个平台提供总线型服务，通过服务调度系统构建良好的服务总线，从而满足系统的开放性、灵活性的要求。
            </p>
            <p></p>
            <p></p>
            <p></p>
            <!-- <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平台采用SOA架构设计与开发，以信息资源数据为核心，构建服务总线框架，满足组件式、插拔式系统扩展接口，具备服务总线、服务管理、运维管理和良好的二次开发能力。平台在本项目的应用大大提升了系统软件、外部数据资源的集成性和开发的技术无关性，促进各类业务系统的紧密配合。拟建设：</p>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 用户及权限管理系统</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 统一消息管理系统</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 统一应用管理系统</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 统一工作流引擎系统</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 统一表单管理系统</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 搜索引擎和全文检索系统</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 报表打印系统</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 统一配置与监管系统</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 知识库管理系统</p>
            <p></p> -->
            <el-image :src="require('@/assets/img/overview_mysql.png')" style="margin-left: 24%;"></el-image>
            <p class="img_title">数据库建设（图1）</p>
            <hr style="height:3px;border:none;border-top:3px double #D3DCE6;" />
            <li style="font-weight: bolder;margin-bottom: 1%;font-size: 20px;">数据库建设</li>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杨凌农业人力资源共享服务平台各类信息在对外共享发布前，来源不同、数据格式各异、数据的内容和质量都存在未知。因此需对外部获取、用户上传、平台存储的数据进行甄别、筛选、整理、归类，形成符合入库标准格式的数据库或数据集。</p>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公共数据库主要建设内容包括：基础类、支撑类、专题类三个类别的数据库建设。其中，基础类包括平台的各类人群基础数据、用户产业信息、身份信息、培训信息、购销信息等；支持类包括：各类政务整合数据、目录及交换数据库等；专题类包括专业技能数据、市场数据、岗位数据、服务需求数据等。</p>
            <p></p>
            <p></p>
            <hr style="height:3px;border:none;border-top:3px double #D3DCE6;" />
            <li style="font-weight: bolder;margin-bottom: 1%;font-size: 20px;">服务接口建设</li>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务接口导航：系统提供多角度，多类别的服务导航功能，用户可查看所有已生成的相关数据服务，并可通过服务类型、服务提供方式、关键字等进行查询。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务接口调用：服务使用者可对已发布各类数据服务进行集成和调用，针对Web Service服务，平台为服务使用者提供其权限内能够使用服务的调用示例代码和该服务的WSDL文件，供服务使用者下载使用，用户可根据自己实际业务的需求进行使用或二次开发。提供服务调度的向导，从页面上详细展示服务的描述信息和调用过程，辅助用户完成服务调用。</p>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务申请审批：服务管理者可对服务使用者提出的服务使用申请进行审批，填写的审批意见进行审批通过或退回操作。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务接口定制：根据所有已审批通过的服务申请或自身业务需求，进行各类数据服务接口的定制和生成，实现对现有数据资源进行服务化和接口组装。系统提供了通过web界面实现简单Web Service 服务生成的功能。系统提供比对验证、信息查询、信息下载、服务记录、综合评分、和统计分析等多种服务接口。</p>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务接口注册：服务管理者对已生成的服务，填写服务相关信息，例如服务名称、服务描述、服务地址、服务调用示例等内容，完成服务注册和发布。服务接口注册成功后，方可生效，服务申请者可进行调用。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务接口维护：提供服务接口的变更、撤销、删除等维护服务，并可进行服务版本信息的查看，以服务编码为依据，将服务的注册、变更、申请、审批、撤销等所有数据进行串联，形成以编码为基础版本为依据的全部信息的展示。</p>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务接口调度：服务管理者对平台中维护的服务接口进行启动、停止、挂起、恢复、测试等调度工作，以满足日常的运维管理需要。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务接口监控：平台提供多种服务接口监控功能，让管理人员能够随时掌握平台已发布的各类服务接口的运行状态，为管理操作提供依据。服务接口监控可对所有已发布的数据服务的当前运行状态以及实时的调用情况进行监控和管理，并且能够进一步追踪到该服务当前的负载情况、服务配置情况、服务调用异常追踪等。根据服务监控结果，管理者可以通过调整服务质量，保证优质服务优先享有网络带宽、存储等资源。</p>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务接口统计：提供全方位、多维度、多形式的服务统计分析，力求能够为管理者提供直观、简单明了的统计分析，为管理决策提供依据。包括服务分布统计、服务运行状态统计、服务在线统计、服务申请统计、服务需求统计、服务调用统计、数据吞吐量统计等。</p>
            <p></p>
            <hr style="height:3px;border:none;border-top:3px double #D3DCE6;margin-top: 2%;" />
            <li style="font-weight: bolder;margin-bottom: 1%;font-size: 20px;">数据共享方式</li>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;共享服务平台与省级平台大数据库对接：使用前置交换数据库模式对接, 统一与省级平台大数据库把数据对接到前置机上, 我方从前置机获取数据。提供2种传输方式:</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 不敏感数据使用非加密传输方式</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 敏感数据使用加密传输方式</p>
            <hr style="height:1px;border:none;border-top:1px dashed #D3DCE6;margin-top: 1%;margin-bottom: 1%;" />
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;共享服务平台与其他业务系统对接：采用微服务模式, 服务之间调用使用API网关。在系统设计之初就考虑对外扩展性。在API网关之上部署统一开放平台, 业务系统统一和开放平台进行对接, 开放平台除了常用接口对接模式之外, 还提供如下几种常用对接模式:</p>
            <p></p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* js嵌套页面对接</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 数据库前置交换</p>
            <p></p>
            <hr style="height:1px;border:none;border-top:1px solid #D3DCE6;" /> 
            <p></p>
            <p style="font-weight: 900;">~以上如有疑问，可前往右下角联系方式，进一步沟通~</p>
            <p></p>
            <p></p>
            <p class="message">Tel: 029-9899089887</p>
            <p class="message">E-mail:diandianbangnong@nongliankeji.com</p>
          </article>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>



<script>
export default {
  data() {
    return {
    }
  },
  created() {

  },
  methods: {

  }
}


</script>
<style scoped lang="less">
article > p, article > li {
  height: 40px;
  line-height: 40px;
}

.title {
    font-size: 28px;
    color: #000;
    font-weight: bold;
    text-align: center;
}

.img_title {
    font-size: 20px;
    margin-bottom: 2%;
    color: #000;
    font-weight: bold;
    text-align: center;
}

.message {
   text-align: right;
   color: #FF0000;
   font-weight: bolder;
}
</style>