<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">常见问题</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">常见问题
            <p class="updateTime">更新时间：2022-10-14</p>
          </div>
          <article>
            <h4>1.接口调用提示凭证过期？</h4>
            <p>答：如果在个人开发中，原有的接口凭证过期，无法再进行正常开发。需再次去使用指导->申请合作重新提交一份新的个人需求信息，等待运营人员在杨凌后台生成新的调用凭证，</p>
            <p>线下互相联系，获取新的可用的调用凭证，继续个人开发研究。</p>
            <p></p>
            <h4>2.平台会提供哪些类型的服务数据？</h4>
            <p>答：我们平台会提供各种农业大数据，如农户种植，采摘，销售农产品，需要的的服务，要干的活（任务），农产品旺季供应，采购等比较集中的线上相关数据，还有一些线上农产品教学，培训的数据。</p>
            <p>除此之外，杨凌农业人力资源共享服务平台里面涵盖了多种服务体系数据，如劳务服务的评价数据、技能测评的数据、知识问答的服务数据、人才科技成果数据、人才荣誉数据；</p>
            <p>打通省级平台用户的社保数据、就失业数据、参加技能培训的数据等。</p>
            <p></p>
            <h4>3.平台没有我想要的数据？</h4>
            <p>答：如果您在个人开发过程中，需要的数据，不能通过本平台的调用凭证获取。您可以通过我们平台上显示的联系方式，直接打电话咨询后台运营人员，看能否提供自己想要的数据。</p>
            <p>或者把自己的的需要通过邮件的形式发给本平台负责人，运营人员会及时查看邮件内容，帮助您寻找您想要的数据。</p>
            <p></p>
            <p></p>
            <p></p>
          </article>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>



<script>
export default {
  data() {
    return {
     
    }
  },
  created() {
    
  },
  methods: {

  }
}



</script>
<style scoped lang="less">
article > p , article > h4 {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-family: monospace,monospace;
}
</style>