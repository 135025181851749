<template>
  <div class="content">
     <p class="ylTitle">杨凌农业人力资源共享服务开放平台</p>
     <p class="ylDesc">着力从农业人力资源共享服务、农业人力资本服务、监管及考评服务、人才数据决策服务这几个方面提升价值。</p>
     <div class="yanglingbg1Sty">
       <button type="primary" class="focusBut" @mouseover="mouseOver" @mouseleave="mouseOut">立即关注</button>
       <div class="focusCard" ref="homeFocus">
          <el-image :src="require('@/assets/img/ylqr.jpg')" class="focusImg"></el-image>
          <p>关注&nbsp;&nbsp;“<span style="color: rgba(41, 100, 255, 1);font-weight: bolder;">新雨</span>”&nbsp;&nbsp;公众号</p>
          <p style="margin-top: -8%;">获取更多农业资讯</p>
       </div>
     </div>
     <div>
       <el-image :src="require('@/assets/img/isometric.png')" class="isometricSty"></el-image>
     </div>
     <div class="funSty">
       <p class="titleRec">杨凌农业人力资源共享服务开放平台</p>
       <el-image :src="require('@/assets/img/person.png')"></el-image>
       <el-image :src="require('@/assets/img/task.png')"></el-image>
       <el-image :src="require('@/assets/img/answer.png')"></el-image>
       <p class="recDesc">
         <span>找能人</span>
         <span>接任务</span>
         <span>在线问答</span>
       </p>
       <p class="recContentDesc">
         <span>实用人才自主选择任务，在线接单</span>
         <span>雇主在线选择劳务队，在线派活</span>
         <span>各类专业人才在线解答农技知识问题</span>
       </p>
     </div>
     <div class="recom">
       <!-- <el-image :src="require('@/assets/img/recommend.png')" class="recommend"></el-image> -->
       <div class="recomHead"></div>
       <div class="recomPage">
         <div class="recomPhone" @mouseover="onMouseOver" @mouseleave="onMouseOut">
            <el-image :src="require('@/assets/img/recomPhone1.png')"></el-image>
            <el-image :src="require('@/assets/img/recomPhone2.png')"></el-image>
            <el-image :src="require('@/assets/img/recomPhone3.png')"></el-image>
            <el-image :src="require('@/assets/img/recomPhone4.png')"></el-image>
            <div class="recomMan" ref="person">
                <el-image :src="require('@/assets/img/recomMan1.png')" style="top: -207px;left: 5.2%;"></el-image>
                <el-image :src="require('@/assets/img/recomMan2.png')" style="top: -287px;left: 9.9%;"></el-image>
                <el-image :src="require('@/assets/img/recomMan3.png')" style="top: 43px;left: -19.9%;"></el-image>
                <el-image :src="require('@/assets/img/recomMan4.png')" style="top: -6px;left: -12.7%;"></el-image>
            </div>
         </div>
       </div>
     </div>
     <div class="worthbg">
       <el-image :src="require('@/assets/img/worth.png')" class="worthSty"></el-image>
       <div class="worthDec">
          <p class="worthDecTitle">杨凌农业人力资源共享服务开放平台</p>
          <p class="worthDecContent">着力从农业人力资源共享服务、农业人力资本服务、监管及考评服务、人才数据决策服务这几个方面提升价值。</p>
       </div>
     </div>
     <div>
       <el-image :src="require('@/assets/img/talent.png')" class="talent"></el-image>
       <div class="talentDec">
          <p class="talentDecTitle">人才技能精准提升</p>
          <p class="talentDecContent">1、根据用户画像精准的为用户推荐适应的课程，整合人社口相关培训，将线下培训进行在线直播、直播预约，挖掘平台优质专家人才、实用人才录制专业课程，实现线上+线下、直播+录播结合的农业人才数字化培育体系。
             <br>2、线下培训专家同步上线，培训机构的学员在培训完成后，有问题随时可以联系相关专家。
             <br>3、对培训专家的服务进行在线评价，实现人社局对培训质量的监管。
          </p>
       </div>
     </div>
     <div>
       <el-image :src="require('@/assets/img/message.png')" class="message"></el-image>
       <div class="messageDec">
          <p class="messageDecTitle">消息即时通讯</p>
          <p class="messageDecContent">消息页面主要是：问答咨询消息、服务咨询消息和任务咨询消息以及平台发布的官方消息。</p>
       </div>
     </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    /*立即关注*/
    //鼠标移入
    mouseOver(){
      this.$refs.homeFocus.style.display = 'block';
    },
    // 鼠标移出
    mouseOut(){
      this.$refs.homeFocus.style.display = 'none';
    },

    /*找能人*/
    //鼠标移入
    onMouseOver(){
      this.$refs.person.style.display = 'block';
    },
    // 鼠标移出
    onMouseOut(){
      this.$refs.person.style.display = 'none';
    }
  }
}

</script>
<style scoped lang="less">
.content {
  top: -35px;
  background: url('../../assets/img/backgroundDecor.png') no-repeat;
  background-size: 1872px 4843px;
  width: 1872px;
  height: 4360px;
  margin-left: -1.1%;
}

.yanglingbg1Sty {
   margin-left: 0.1%;
   margin-top: -13.1%;
   background: url("../../assets/img/yanglingbg1.png") no-repeat;
   background-size: 1864px 755.61px;
   width: 1864px;
   height: 755.61px;
}

.ylTitle {
  padding-left: 5%;
  padding-top: 5%;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 60px;
  color: rgba(17, 34, 63, 1);
}

.ylDesc {
  padding-left: 5%;
  padding-top: 5%;
  width: 600px;
  height: 80px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 40px;
  color: rgba(17, 34, 63, 1);
}

.focusBut {
    margin-left: 5%;
    margin-top: 20%;
    width: 300px;
    min-height: 50px;
    font-weight: bolder;
    font-size: 15px;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    background: rgba(25, 104, 252, 1);
    box-shadow: 0px 10px 40px 0px rgba(25, 104, 252, 0.3);
    cursor: pointer;
}

.focusCard {
     margin-bottom: -19.5%;
     margin-top: 1%;
     margin-left: 7.8%;
     width: 196px;
     height: 257px;
     background: url("../../assets/img/focus.png");
     border-radius: 10px;
     background: rgba(255, 255, 255, 1);
     box-shadow: 0px 0px 11px 0px rgba(221, 224, 235, 1);
     display: none;
     .focusImg {
       margin-top: 3.5%;
       margin-left: 7.6%;
       width: 164px;
       height: 164px;
       border-radius: 10px;
     }
     p {
        margin-left: 17%;
        margin-top: 11%;
        width: 126px;
        height: 41px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 0px;
        color: rgba(0, 0, 0, 1);
        text-align: center;
        vertical-align: top;
     }
}

.isometricSty {
   left: 730px;
   top: -882px;
   width: 1200px;
   height: 800.75px;
}

.funSty {
   margin-left: 1%;
   margin-top: 10.6%;
   .el-image {
     padding-left: 20%;
   }
   .titleRec {
      margin-left: 30%;
      margin-top: -33%;
      margin-bottom: 7%;
      font-size: 40px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 60px;
      color: rgba(17, 34, 63, 1);
    }
  .recDesc {
    padding-left: 20.25%;
    margin-top: 1%;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    color: rgba(17, 34, 63, 1);
    span {
      padding-right: 26%;
    }
  }
  .recContentDesc {
    padding-left: 16%;
    margin-top: 1%;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 30px;
    color: rgba(17, 34, 63, 1);
    span {
      padding-right: 15%;
    }
  }
}

.recom {
  margin-left: 26%;
  margin-top: 7%;
  .recomHead {
      width: 100%;
      height: 35px;
      background: url("../../assets/img/recomHead.png") no-repeat;
      border-radius: 10px 10px, 0px, 0px;
    }

    .recomPage {
      width: 100%;
      height: 415px;
      background: url("../../assets/img/recomPage.png") no-repeat;
      .recomPhone {
        margin-left: -7.2%;
         .el-image {
            margin-top: 2.4%;
            margin-right: 1.8%;
            border-radius: 30px;         
        }
      }
    }
}

.recomMan {
   margin-top: -26%;
   margin-left: 10%;
   display: none;
}

.recommend {
  margin-left: 20%;
  margin-top: 7%;
}

.worthbg {
  width: 100%;
  height: 543px;
  background: url("../../assets/img/worthbg.png") no-repeat;
  margin-top: 4%;
}

.worthSty {
  margin-left: 13%;
  margin-top: 10%;
}

.worthDec {
  margin-top: -16%;
  margin-left: 50%;
  .worthDecTitle {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 60px;
    color: rgba(17, 34, 63, 1);
  }
  .worthDecContent {
    margin-top: 5%;
    width: 700px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 40px;
    color: rgba(17, 34, 63, 1);
  }
}

.talent {
  margin-left: 70%;
  margin-top: 2.8%;
}

.talentDec {
  margin-left: 6%;
  margin-top: -17.3%;
  .talentDecTitle {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 60px;
    color: rgba(17, 34, 63, 1);
  }
  .talentDecContent {
    margin-top: 2%;
    margin-left: 0px;
    width: 600px;
    height: 80px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 40px;
    color: rgba(17, 34, 63, 1);
  }
}

.message {
  margin-left: 10%;
  margin-top: 34.4%;
}

.messageDec {
   margin-top: -29.2%;
   margin-left: 60%;
  .messageDecTitle {
      font-size: 40px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 60px;
      color: rgba(17, 34, 63, 1);
  }
  .messageDecContent {
    margin-top: 5%;
    margin-left: 0px;
    width: 600px;
    height: 80px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 40px;
    color: rgba(17, 34, 63, 1);
  }
}
</style>