<!DOCTYPE>
<template>
  <div>
    <!-- 面包屑 -->
    <el-row style="line-height: 160px;">
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item>使用指导</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">申请合作</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">添加用户信息</div>
            <div class="wrap">
              <div class="formWidth">
                <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
                  <div class="grid-content">
                    <label>用户名</label><span style="color: #FF0000;">*</span>
                    <el-form-item label="" :label-width="formLabelWidth" prop="userName" class="inputMarg">
                      <el-input v-model="form.userName" autocomplete="off" placeholder="请输入您的姓名"></el-input>
                    </el-form-item>
                    <label>电话号码</label><span style="color: #FF0000;">*</span>
                    <el-form-item label="" :label-width="formLabelWidth" prop="userPhone" class="inputMarg">
                      <el-input v-model="form.userPhone" autocomplete="off" placeholder="请输入您的电话号码"></el-input>
                    </el-form-item>
                    <label>邮箱</label>
                    <el-form-item label="" :label-width="formLabelWidth" prop="userEmail" class="inputMarg">
                      <el-input v-model="form.userEmail" autocomplete="off" placeholder="请输入您的邮箱"></el-input>
                    </el-form-item>
                    <label>需求描述</label><span style="color: #FF0000;">*</span>
                    <el-form-item label="" :label-width="formLabelWidth" prop="userDesc" class="inputMarg">
                      <el-input v-model="form.userDesc" autocomplete="off" type="textarea" :rows="6" resize="none" placeholder="请输入您申请调用凭证（密匙）的用途或具体要调用平台的哪个服务接口"></el-input>
                    </el-form-item>
                  </div>
                </el-form>
              </div>
              <!-- 提交按钮 -->
              <div style="margin-top: -9%;">
                <button type="primary" class="formButton" @click="submitForm('form')">提交</button>
              </div>
              <div class="info_sec">申明：我们将严格对您提交的信息按照相关法律规定做好保密制度，并且收集到的信息只用于本平台必要的工作，绝不随意传播，买卖等。</div>
            </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表单信息
      form: {
        userName: '',
        userPhone: '',
        userEmail: '',
        userDesc: ''
      },
      // 表单信息验证项
      rules: {
        userName: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        userPhone: [
          { required: true, message: '请输入您的电话号码', trigger: 'blur' },
        ],
        userEmail: [
          { required: false, message: '请输入您的邮箱', trigger: 'blur' },
        ],
        userDesc: [
          { required: true, message: '请输入您申请调用凭证', trigger: 'blur' },
        ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'right'
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  methods: {
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveUserInfo()
        }
      });
    },
    async saveUserInfo() {
      const { data: res } = await this.$http.post("/open/openUser/toAddOpenUser?userDesc="+this.form.userDesc+'&userEmail='+this.form.userEmail+'&userPhone='+this.form.userPhone+'&userName='+this.form.userName)
      if (res.code != 200) return this.$message.error(res.message);
      // this.$message.success(res.message);
      this.$message.success("您提交的合作信息已经成功，我们运营人员会尽快处理您的合作需求！");
      // setTimeout(() => {
      //   this.$router.go(-1)
      // }, 1000);
    }
  }
}
</script>
<style scoped lang="less">
.info_sec {
  margin-top: 16.5%;
  margin-left: 25%;
  font-size: 10px;
  font-weight: bolder;
  color:#D3DCE6;
}

.grid-content {
  label {
    font-size: 14px;
  }
}

.inputMarg {
  margin-left: -2.5%;
  margin-top: -10%;
  margin-bottom: -3.5%;
  width: 80%;
}

.formButton {
  margin-top: 0.3%;
  margin-left: 44%;
  font-weight: bolder;
  color: #FFFFFF;
  width: 100px;
  height: 35px;
  line-height: 35px;
  opacity: 1;
  border: none;
  background: rgba(25, 104, 252, 1);
  box-shadow: 0px 10px 40px 0px rgba(25, 104, 252, 0.3);
  border-radius: 5px;
  cursor: pointer;
}
</style>