<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item>使用指导</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">接口使用流程</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">接口调用凭证获取步骤
             <p class="updateTime">更新时间：2022-10-14</p>
          </div>
          <div>
            <el-steps direction="vertical" :active="5" space="400px" size="medium">
              <el-step title="申请合作" description="进入使用指导目录中申请合作页面，填写申请合作表格内容信息，姓名，联系电话，邮箱，需求简述，其中邮箱信息是非必填内容。"></el-step>
              <el-step title="确定使用需求" description="运营人员在杨凌平台后台查看用户提交的表格内容，线下联系使用者确定接口使用需求。"></el-step>
              <el-step title="生成接口调用凭证（密匙）" description="运营人员在杨凌后台对该用户生成对应接口的唯一凭证（密匙），并且可维护该凭证有效性。"></el-step>
              <el-step title="发送调用凭证" description="运营人员在后台生成好可使用的调用凭证（密匙）后，线下及时发送调用凭证给用户，确保调用凭证在发送给过程中的安全性。"></el-step>
              <el-step title="接口调用" description="用户获取到调用凭证（密匙），根据对应接口文档调用申请的接口，实现自身开发需求。"></el-step>
            </el-steps>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>



<script>
export default {
  data() {
    return {
  
    }
  },
  created() {
    
  },
  methods: {

  }
}



</script>
<style scoped lang="less">
.box-card {
  .el-steps {
     height: 1000px;
     line-height: 0px !important;
  }
}
</style>