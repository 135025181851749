<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item>数据服务</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">供应推荐</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">供应推荐接口描述
             <p class="updateTime">更新时间：2022-10-14</p>
          </div>
          <article>
            <h3>获取供应推荐信息</h3>
            <h4>1.描述</h4>
            <p>该接口用户获取平台中发布的供应推荐信息，这些供应信息数据包含了供应的名称，介绍，数量，品种，单位，开始时间，结束时间等等，用户用这些数据信息可以进行个人开发研究，</p>
            <p>同时分析供应数据所占品种，数量的比例，帮助农户分析种植农产品的种类，数量，投资方向，盈利数额。</p>
            <p></p>
            <h4>2.请求接口</h4>
            <div class="codeSty">
              <p>POST</p>
              <p style="color: #10aeff;">http://alspd.natapp1.cc/open/openData/getSupplyInfo?certificate=fa2cd83daa584353a1089858804fee42</p>
            </div>
            <p></p>
            <h4>3.请求参数</h4>
            <el-table 
               :data="paramData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="参数名">
              </el-table-column>
              <el-table-column prop="needs" label="是否必填">
              </el-table-column>
              <el-table-column prop="usage" label="作用">
              </el-table-column>
            </el-table>
            <p></p>
            <h4>4.返回值</h4>
            <el-table 
               :data="retData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="参数名">
              </el-table-column>
              <el-table-column prop="desc" label="描述">
              </el-table-column>
            </el-table>
            <p></p>
            <h4>5.返回示例</h4>
            <div class="codeSty">
              <p>{</p>
              <div style="margin-left: 4%;">
                <p>&nbsp;<label>"success":</label>&nbsp;<span>true</span>,</p>
                <p>&nbsp;<label>"code":</label>&nbsp;<span>200</span>,</p>
                <p>&nbsp;<label>"message":</label>&nbsp;<span>"请求成功"</span>,</p>
                <p>&nbsp;<label>"data":</label>&nbsp;[</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"supplyName":</label>&nbsp;<span>"黄辣椒"</span>,</p>
                <p>&nbsp;&nbsp;<label>"supplyBreed":</label>&nbsp;<span>"X射线超级大辣椒"</span>,</p>
                <p>&nbsp;&nbsp;<label>"supplyNum":</label>&nbsp;<span>100</span>,</p>
                <p>&nbsp;&nbsp;<label>"supplyUnit":</label>&nbsp;<span>"斤"</span></p>
                <p>&nbsp;},</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"supplyName":</label>&nbsp;<span>"大量已剥玉米粒"</span>,</p>
                <p>&nbsp;&nbsp;<label>"supplyBreed":</label>&nbsp;<span>"玉米粒"</span>,</p>
                <p>&nbsp;&nbsp;<label>"supplyNum":</label>&nbsp;<span>500</span>,</p>
                <p>&nbsp;&nbsp;<label>"supplyUnit":</label>&nbsp;<span>"公斤"</span></p>
                <p>&nbsp;}]</p>
              </div>
              <p>}</p>
            </div>
            <p></p>
          </article>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>



<script>
export default {
  data() {
    return {
      paramData: [],
      retData: []
    }
  },
  created() {
    this.paramData = [
      {
        name: 'certificate',
        needs: '必填',
        usage: '接口调用凭证'
      }
    ],
    this.retData = [
      {
        name: 'supplyName',
        desc: '供应名称'
      },
      {
        name: 'supplyBreed',
        desc: '供应品种'
      },
      {
        name: 'supplyNum',
        desc: '供应数量'
      },
      {
        name: 'supplyUnit',
        desc: '供应单位'
      }
    ]
  },
  methods: {

  }
}


</script>
<style scoped lang="less">
article > p, article > h3, article > h4 {
  height: 40px;
  line-height: 40px;
  font-family: -apple-system,BlinkMacSystemFont,SF UI Text,Helvetica Neue,PingFang SC,Hiragino Sans GB,Microsoft YaHei UI,Microsoft YaHei,Arial,sans-serif;
}

.codeSty {
  background: #f9f9fa;
  border: #f9f9fa 1px solid;
  font-family: Consolas,Liberation Mono,Menlo,Courier,monospace;
  p {
      height: 40px;
      line-height: 40px;
      margin: 10px;
  }
  label {
    color: #fa5151;
  }
  span {
    color: #07c160;
  }
}
</style>