<template>
  <el-container class="container">
    <el-aside :width="isCollapse?'64px':'250px'">
      <div class="toggle-button">
      </div>
      <el-row>
        <el-col>
          <el-menu :default-active="activePath" class="el-menu-vertical-demo" unique-opened :collapse="isCollapse" router :collapse-transition="false" background-color="#f9f9f9" text-color="#000000" active-text-color="#1990FF">
            <template v-for="item in menuList">
              <template v-if="item.children">
                <el-submenu :index="item.id" :key="item.id">
                  <template slot="title">
                    <!-- <i :class="item.icon"></i> -->
                    <span slot="title">{{ item.title }}</span>
                  </template>
                  <el-menu-item :index="subItem.id" v-for="(subItem,i) in item.children" :key="i" @click="saveNavState(subItem.id)">
                    <template>
                      <span slot="title">{{ subItem.title }}</span>
                    </template>
                  </el-menu-item>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item :index="item.id" :key="item.id" @click="saveNavState(item.id)">
                  <template slot="title">
                    <!-- <i :class="item.icon"></i> -->
                    <span slot="title">{{ item.title }}</span>
                  </template>
                </el-menu-item>
              </template>
            </template>
          </el-menu>
        </el-col>
      </el-row>
    </el-aside>
    <el-main class="main">
      <router-view></router-view>
    </el-main>
  </el-container>
</template>


<script>
export default {
  data() {
    return {
      // logo: require('@/assets/logo.jpg'),
      isCollapse: false,
      activePath: '/overview',
      menuList: [
      {
        id: '/plat_introduce',
        icon: 'el-icon-s-order',
        title: "平台介绍",
        children: [
          {
            id: '/overview',
            title: '平台概述'
          }
        ]
      },
      {
        id: '/guide',
        icon: 'el-icon-circle-plus',
        title: "使用指导",
        children: [
          {
            id: '/flow',
            title: '接口使用流程'
          },
          {
            id: '/cooperation',
            title: '申请合作'
          }
        ]
      },
      {
        id: '/data_server',
        icon: 'el-icon-s-order',
        title: "数据服务",
        children: [
          {
            id: '/usage',
            title: '使用须知'
          },
          {
            id: '/teaching',
            title: '培训教学'
          },
          {
            id: '/server',
            title: '服务推荐'
          },
          {
            id: '/task',
            title: '任务推荐'
          },
           {
            id: '/supply',
            title: '供应推荐'
          },
          {
            id: '/purchase',
            title: '采购推荐'
          },
          {
            id: '/retstatus',
            title: '平台返回状态码'
          }
        ]
      },
      {
        id: '/issue',
        title: '常见问题'
      }
     ]
    }
  },
  methods: {
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
    },
    // 点击按钮切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    }
  },
  created() {
    // this.activePath = window.sessionStorage.getItem('activePath')
  }
}
</script>



<style scoped lang="less">
.container {
  .header {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    line-height: 80px;
    box-shadow: 0px 0px 20px 1px #eee;
    text-align: right;
    .icon {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .main {
    background: #FFFFFF;
    position: relative;
    padding-top: 30px;
  }
}
//侧边栏背景色
.el-aside {
  background:#f9f9f9;
  color: #000000;
  margin-left: -20px;
  overflow: hidden;
  .el-menu {
    border-right: none;
  }
  // 侧边栏展开
  .toggle-button {
    background-color: #FFFFFF;
    opacity: 1;
    font-size: 12px;
    color: #000000;
    text-align: center;
    letter-spacing: 0.2em;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
      margin-top: 5px;
    }
  }
}
</style>