<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item>数据服务</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">采购推荐</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">采购推荐接口描述
              <p class="updateTime">更新时间：2022-10-14</p>
          </div>
          <article>
            <h3>获取采购推荐信息</h3>
            <h4>1.描述</h4>
            <p>该接口用户获取平台中发布的采购推荐信息，这些采购数据信息包含了采购名称，品种，数量，单位，价格，开始时间，结束时间等等，用户用这些数据信息进行个人开发，数据分析，</p>
            <p>从而得到绝大多数人采购农产品的需求，分析采购品种趋势，数量，各个产品的时间段，引导农户恰逢时宜做好农产品供应。</p>
            <p></p>
            <h4>2.请求接口</h4>
            <div class="codeSty">
              <p>POST</p>
              <p style="color: #10aeff;">http://alspd.natapp1.cc/open/openData/getNeedInfo?certificate=fa2cd83daa584353a1089858804fee42</p>
            </div>
            <p></p>
            <h4>3.请求参数</h4>
            <el-table 
               :data="paramData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="参数名">
              </el-table-column>
              <el-table-column prop="needs" label="是否必填">
              </el-table-column>
              <el-table-column prop="usage" label="作用">
              </el-table-column>
            </el-table>
            <p></p>
            <h4>4.返回值</h4>
            <el-table 
               :data="retData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="参数名">
              </el-table-column>
              <el-table-column prop="desc" label="描述">
              </el-table-column>
            </el-table>
            <p></p>
            <h4>5.返回示例</h4>
            <div class="codeSty">
              <p>{</p>
              <div style="margin-left: 4%;">
                <p>&nbsp;<label>"success":</label>&nbsp;<span>true</span>,</p>
                <p>&nbsp;<label>"code":</label>&nbsp;<span>200</span>,</p>
                <p>&nbsp;<label>"message":</label>&nbsp;<span>"请求成功"</span>,</p>
                <p>&nbsp;<label>"data":</label>&nbsp;[</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"needUnit":</label>&nbsp;<span>"吨"</span>,</p>
                <p>&nbsp;&nbsp;<label>"needNum":</label>&nbsp;<span>50</span>,</p>
                <p>&nbsp;&nbsp;<label>"needDesc":</label>&nbsp;<span>"水分要足够"</span>,</p>
                <p>&nbsp;&nbsp;<label>"needName":</label>&nbsp;<span>"需要大量种植沙子"</span></p>
                <p>&nbsp;},</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"needUnit":</label>&nbsp;<span>"公斤"</span>,</p>
                <p>&nbsp;&nbsp;<label>"needNum":</label>&nbsp;<span>500</span>,</p>
                <p>&nbsp;&nbsp;<label>"needDesc":</label>&nbsp;<span>"甜味要够"</span>,</p>
                <p>&nbsp;&nbsp;<label>"needName":</label>&nbsp;<span>"荔枝蜜"</span></p>
                <p>&nbsp;}]</p>
              </div>
              <p>}</p>
            </div>
            <p></p>
          </article>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>



<script>
export default {
  data() {
    return {
      paramData: [],
      retData: []
    }
  },
  created() {
    this.paramData = [
      {
        name: 'certificate',
        needs: '必填',
        usage: '接口调用凭证'
      }
    ],
    this.retData = [
      {
        name: 'needName',
        desc: '采购名称'
      },
      {
        name: 'needDesc',
        desc: '采购描述品种'
      },
      {
        name: 'needNum',
        desc: '采购数量'
      },
      {
        name: 'needUnit',
        desc: '采购单位'
      }
    ]
  },
  methods: {

  }
}


</script>
<style scoped lang="less">
article > p, article > h3, article > h4  {
  height: 40px;
  line-height: 40px;
  font-family: -apple-system,BlinkMacSystemFont,SF UI Text,Helvetica Neue,PingFang SC,Hiragino Sans GB,Microsoft YaHei UI,Microsoft YaHei,Arial,sans-serif;
}

.codeSty {
  background: #f9f9fa;
  border: #f9f9fa 1px solid;
  font-family: Consolas,Liberation Mono,Menlo,Courier,monospace;
  p {
      height: 40px;
      line-height: 40px;
      margin: 10px;
  }
  label {
    color: #fa5151;
  }
  span {
    color: #07c160;
  }
}
</style>