import Vue from 'vue'
import VueRouter from 'vue-router'
// import login from '../components/login/login.vue' //登录
import home from '../view/home.vue' //整体主页
import openPlat from '../view/openPlat.vue' //开放平台页面

import homeContent from '../components/home_content/homeContent.vue' //主页内容
import overview from '../components/plat_introduce/overview.vue' //平台概述

import flow from '../components/user_guide/flow.vue' //使用指导
import cooperation from '../components/user_guide/cooperation.vue'

import usage from '../components/data_server/usage.vue' //使用须知
import teaching from '../components/data_server/teaching.vue' //服务介绍
import task from '../components/data_server/task.vue'
import server from '../components/data_server/server.vue'
import supply from '../components/data_server/supply.vue'
import purchase from '../components/data_server/purchase.vue'
import retstatus from '../components/data_server/retstatus.vue'

import issue from '../components/issue/issue.vue'


Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/', redirect: '/home' },
        {
            path: '/home', 
            component: home,
            redirect: '/openPlat',
            children: [
                {
                    path: '/homeContent',
                    component: homeContent
                },
                {
                    path: '/openPlat',
                    component: openPlat,
                    redirect: '/overview',
                    children: [
                        // 平台概述-------------------------------------------------------
                        {
                            path: '/overview',
                            component: overview
                        },
                        //使用指导
                        {
                            path: '/flow',
                            component: flow
                        },
                        {
                            path: '/cooperation',
                            component: cooperation
                        },
                        //使用须知
                        {
                            path: '/usage',
                            component: usage
                        },
                        {
                            path: '/teaching',
                            component: teaching
                          },
                          {
                            path: '/server',
                            component: server
                          },
                          {
                            path: '/task',
                            component: task
                          },
                           {
                            path: '/supply',
                            component: supply
                          },
                          {
                            path: '/purchase',
                            component: purchase
                          },
                          {
                            path: '/retstatus',
                            component: retstatus
                          },
                          {
                            path: '/issue',
                            component: issue
                          }
                    ]
                },
            ]
        },
        // { path: '/login', component: login }
    ]
})
router.beforeEach((to, from, next) => {
    if (to.path === "/login") return next();
    // const tokenStr = window.sessionStorage.getItem("token")
    // if (!tokenStr) return next('/login')
    next()
})
export default router