<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item>数据服务</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">任务推荐</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">任务推荐接口描述
             <p class="updateTime">更新时间：2022-10-14</p>
          </div>
          <article>
            <h3>获取任务推荐信息</h3>
            <h4>1.描述</h4>
            <p>该接口用户获取平台中发布的任务推荐信息,任务推荐数据包含的信息和服务推荐大体一样，也是包括“用户名”，个人标签，个人技能等，用户用这些数据信息进行农户任务相关开发研究，</p>
            <p>分析农户们都有哪些农业任务要做，任务类型分布趋势，任务完成速度提高。</p>
            <p></p>
            <h4>2.请求接口</h4>
            <div class="codeSty">
              <p>POST</p>
              <p style="color: #10aeff;">http://alspd.natapp1.cc/open/openData/getTaskInfo?certificate=fa2cd83daa584353a1089858804fee42</p>
            </div>
            <p></p>
            <h4>3.请求参数</h4>
            <el-table 
               :data="paramData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="参数名">
              </el-table-column>
              <el-table-column prop="needs" label="是否必填">
              </el-table-column>
              <el-table-column prop="usage" label="作用">
              </el-table-column>
            </el-table>
            <p></p>
            <h4>4.返回值</h4>
            <el-table 
               :data="retData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="参数名">
              </el-table-column>
              <el-table-column prop="desc" label="描述">
              </el-table-column>
            </el-table>
            <p></p>
            <h4>5.返回示例</h4>
            <div class="codeSty">
              <p>{</p>
              <div style="margin-left: 4%;">
                <p>&nbsp;<label>"success":</label>&nbsp;<span>true</span>,</p>
                <p>&nbsp;<label>"code":</label>&nbsp;<span>200</span>,</p>
                <p>&nbsp;<label>"message":</label>&nbsp;<span>"请求成功"</span>,</p>
                <p>&nbsp;<label>"data":</label>&nbsp;[</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"taskContent":</label>&nbsp;<span>"要求存活率高，有死苗，不给报酬"</span>,</p>
                <p>&nbsp;&nbsp;<label>"taskUnit":</label>&nbsp;<span>"亩"</span>,</p>
                <p>&nbsp;&nbsp;<label>"taskTitle":</label>&nbsp;<span>"栽蔬菜秧苗"</span>,</p>
                <p>&nbsp;&nbsp;<label>"taskFee":</label>&nbsp;<span>0.2</span></p>
                <p>&nbsp;},</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"taskContent":</label>&nbsp;<span>"玉米皮，玉米粒"</span>,</p>
                <p>&nbsp;&nbsp;<label>"taskUnit":</label>&nbsp;<span>"天"</span>,</p>
                <p>&nbsp;&nbsp;<label>"taskTitle":</label>&nbsp;<span>"剥玉米"</span>,</p>
                <p>&nbsp;&nbsp;<label>"taskFee":</label>&nbsp;<span>30</span></p>
                <p>&nbsp;}]</p>
              </div>
              <p>}</p>
            </div>
            <p></p>
          </article>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>



<script>
export default {
  data() {
    return {
      paramData: [],
      retData: []
    }
  },
  created() {
    this.paramData = [
      {
        name: 'certificate',
        needs: '必填',
        usage: '接口调用凭证'
      }
    ],
    this.retData = [
      {
        name: 'taskTitle',
        desc: '任务标题'
      },
      {
        name: 'taskContent',
        desc: '任务内容'
      },
      {
        name: 'taskFee',
        desc: '任务价格'
      },
      {
        name: 'taskUnit',
        desc: '任务单位'
      }
    ]
  },
  methods: {

  }
}


</script>
<style scoped lang="less">
article > p, article > h3,article > h4 {
  height: 40px;
  line-height: 40px;
  font-family: -apple-system,BlinkMacSystemFont,SF UI Text,Helvetica Neue,PingFang SC,Hiragino Sans GB,Microsoft YaHei UI,Microsoft YaHei,Arial,sans-serif;
}

.codeSty {
  background: #f9f9fa;
  border: #f9f9fa 1px solid;
  font-family: Consolas,Liberation Mono,Menlo,Courier,monospace;
  p {
      height: 40px;
      line-height: 40px;
      margin: 10px;
  }
  label {
    color: #fa5151;
  }
  span {
    color: #07c160;
  }
}
</style>