<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item>数据服务</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">返回状态码</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">返回状态码
             <p class="updateTime">更新时间：2022-10-19</p>
          </div>
          <article>
            <h4>1.返回数据</h4>
            <div class="codeSty">
              <p>{</p>
              <div style="margin-left: 4%;">
                <p>&nbsp;<label>"success":</label>&nbsp;<span>true</span>,</p>
                <p>&nbsp;<label>"code":</label>&nbsp;<span>200</span>,</p>
                <p>&nbsp;<label>"message":</label>&nbsp;<span>"请求成功"</span>,</p>
                <p>&nbsp;<label>"data":</label>&nbsp;[</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"laborContent":</label>&nbsp;<span>"码各种你想要东西"</span>,</p>
                <p>&nbsp;&nbsp;<label>"laborFee":</label>&nbsp;<span>0.12</span>,</p>
                <p>&nbsp;&nbsp;<label>"laborTitle":</label>&nbsp;<span>"码各种东西"</span>,</p>
                <p>&nbsp;&nbsp;<label>"laborUnit":</label>&nbsp;<span>"天"</span></p>
                <p>&nbsp;},</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"laborContent":</label>&nbsp;<span>"个大，颜色亮，口感十足"</span>,</p>
                <p>&nbsp;&nbsp;<label>"laborFee":</label>&nbsp;<span>0.03</span>,</p>
                <p>&nbsp;&nbsp;<label>"laborTitle":</label>&nbsp;<span>"种番薯"</span>,</p>
                <p>&nbsp;&nbsp;<label>"laborUnit":</label>&nbsp;<span>"亩"</span></p>
                <p>&nbsp;},</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"laborContent":</label>&nbsp;<span>"紫薯，土豆，芋头，地瓜，番薯"</span>,</p>
                <p>&nbsp;&nbsp;<label>"laborFee":</label>&nbsp;<span>0.1</span>,</p>
                <p>&nbsp;&nbsp;<label>"laborTitle":</label>&nbsp;<span>"烤红薯"</span>,</p>
                <p>&nbsp;&nbsp;<label>"laborUnit":</label>&nbsp;<span>"斤"</span></p>
                <p>&nbsp;}]</p>
              </div>
              <p>}</p>
            </div>
            <p></p>
            <h4>2.请求返回头信息</h4>
            <div class="codeSty">
              <p><label>cache-control:</label> <span>no-cache, no-store, max-age=0, must-revalidate</span></p>
              <p><label>connection:</label> <span>keep-alive</span></p> 
              <p><label>content-type:</label> <span>application/json;charset=UTF-8</span></p> 
              <p><label>date:</label> <span>Fri, 21 Oct 2022 06:22:46 GMT</span></p> 
              <p><label>expires:</label> <span>0</span></p> 
              <p><label>keep-alive:</label> <span>timeout=60</span></p> 
              <p><label>pragma:</label> <span>no-cache</span></p> 
              <p><label>transfer-encoding:</label> <span>chunked</span></p> 
              <p><label>x-content-type-options:</label> <span>nosniff</span></p> 
              <p><label>x-frame-options:</label> <span>DENY</span></p> 
              <p><label>x-xss-protection:</label> <span>1; mode=block</span></p> 
            </div>
            <p></p>
            <h4>3.参数说明</h4>
            <el-table 
               :data="paramData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="参数名">
              </el-table-column>
              <el-table-column prop="prod" label="说明">
              </el-table-column>
            </el-table>
            <p></p>
            <h4>4.请求返回状态码</h4>
            <el-table 
               :data="retData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="状态码(code)">
              </el-table-column>
              <el-table-column prop="desc" label="描述">
              </el-table-column>
            </el-table>
            <p></p>
          </article>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>



<script>
export default {
  data() {
    return {
        paramData: [],
        retData: []
    }
  },
  created() {
    this.paramData = [ 
      {
        name: 'success',
        prod: '请求成功（true）或失败（false）'
      },
      {
        name: 'code',
        prod: '请求状态返回码，成功（200 OK），详见4.请求状态返回码'
      },
      {
        name: 'message',
        prod: '请求后台返回成功的详细信息或失败的错误反馈详细信息'
      },
      {
        name: 'data',
        prod: '请求返回的真实数据，开发人员用于个人研究开发'
      }
    ],
    this.retData = [
      {
        name: 'http  200',
        desc: '请求成功（OK）'
      },
      {
        name: 'http  201',
        desc: '请求已被接受，等待资源响应（Created）'
      },
      {
        name: 'http  401',
        desc: '需要身份认证验证（Unauthorized）'
      },
      {
        name: 'http  403',
        desc: '禁止访问(Forbidden)'
      },
      {
        name: 'http  404',
        desc: '请求的内容未找到或已删除(Not Found)'
      },
      {
        name: 'http  500',
        desc: '服务器端程序错误（Server Internal Error）'
      }
    ]
  },
  methods: {

  }
}


</script>
<style scoped lang="less">
article > p, article > h3,article > h4 {
  height: 40px;
  line-height: 40px;
  font-family: -apple-system,BlinkMacSystemFont,SF UI Text,Helvetica Neue,PingFang SC,Hiragino Sans GB,Microsoft YaHei UI,Microsoft YaHei,Arial,sans-serif;
}

.codeSty {
  background: #f9f9fa;
  border: #f9f9fa 1px solid;
  font-family: Consolas,Liberation Mono,Menlo,Courier,monospace;
  p {
      height: 40px;
      line-height: 40px;
      margin: 10px;
  }
  span {
      color:#07c160;
  }
  label {
      color:#fa5151;
  }
}
</style>