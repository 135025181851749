<template>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item>数据服务</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">培训教学</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">培训教学接口描述
             <p class="updateTime">更新时间：2022-10-14</p>
          </div>
          <article>
            <h3>获取教学信息</h3>
            <h4>1.描述</h4>
            <p>该接口用户可以获取平台中发布的所有教学视频信息数据，这些信息数据包含了教学视频的教学名称，教学封面，教学简介，教学分数，教学类型，用户评分数等等，用户用这信息除了做一些个人开发外，</p>
            <p>还可以从大量教学视频信息中做一些数据图表分析，获取近几年培训教学内容的趋势，得到部分地区农户线上学习的需求，喜好。</p>
            <p></p>
            <h4>2.请求接口</h4>
            <div class="codeSty">
              <p>POST</p>
              <p style="color: #10aeff;">http://alspd.natapp1.cc/open/openData/getTeachInfo?certificate=fa2cd83daa584353a1089858804fee42</p>
            </div>
            <p></p>
            <h4>3.请求参数</h4>
            <el-table 
               :data="paramData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="参数名">
              </el-table-column>
              <el-table-column prop="needs" label="是否必填">
              </el-table-column>
              <el-table-column prop="usage" label="作用">
              </el-table-column>
            </el-table>
            <p></p>
            <h4>4.返回值</h4>
            <el-table 
               :data="retData" 
               border 
               class="tableSty" 
               :row-style="{height:'40'}" 
               :cell-style="{padding: '5'}" 
               :header-cell-style="{height: '40px',background: '#f5f8ff',fontWeight: '600',textAlign: 'left'}">
              <el-table-column prop="name" label="参数名">
              </el-table-column>
              <el-table-column prop="desc" label="描述">
              </el-table-column>
            </el-table>
            <p></p>
            <h4>5.返回示例</h4>
            <div class="codeSty">
              <p>{</p>
              <div style="margin-left: 4%;">
                <p>&nbsp;<label>"success":</label>&nbsp;<span>true</span>,</p>
                <p>&nbsp;<label>"code":</label>&nbsp;<span>200</span>,</p>
                <p>&nbsp;<label>"message":</label>&nbsp;<span>"请求成功"</span>,</p>
                <p>&nbsp;<label>"data":</label>&nbsp;[</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"teachName":</label>&nbsp;<span>"种植玉米技术"</span>,</p>
                <p>&nbsp;&nbsp;<label>"teachHead":</label>&nbsp;<span>"https://video.ddbangnong.com/10f77aeb-7d94-4db9-ad3f-09a74aeb67c0.jpg"</span></p>
                <p>&nbsp;},</p>
                <p>&nbsp;{</p>
                <p>&nbsp;&nbsp;<label>"teachName":</label>&nbsp;<span>"种植红薯"</span>,</p>
                <p>&nbsp;&nbsp;<label>"teachHead":</label>&nbsp;<span>"https://video.ddbangnong.com/f4869be2-1152-4a23-ab4c-f5a2a6858b0d.jpg"</span></p>
                <p>&nbsp;}]</p>
              </div>
              <p>}</p>
            </div>
            <!-- <p>sucess,message,data含义</p> -->
            <p></p>
          </article>
        </el-card>
      </el-col>
    </el-row>
</template>



<script>
export default {
  data() {
    return {
      paramData: [],
      retData: []
    }
  },
  created() {
    this.paramData = [
      {
        name: 'certificate',
        needs: '必填',
        usage: '接口调用凭证'
      }
    ],
    this.retData = [
      {
        name: 'teachName',
        desc: '教学名称'
      },
      {
        name: 'teachHead',
        desc: '教学封面图'
      }
    ]
  },
  methods: {

  }
}


</script>
<style scoped lang="less">
article > p, article > h3, article > h4 {
  height: 40px;
  line-height: 40px;
  font-family: -apple-system,BlinkMacSystemFont,SF UI Text,Helvetica Neue,PingFang SC,Hiragino Sans GB,Microsoft YaHei UI,Microsoft YaHei,Arial,sans-serif;
}

.codeSty {
  background: #f9f9fa;
  border: #f9f9fa 1px solid;
  font-family: Consolas,Liberation Mono,Menlo,Courier,monospace;
  border-radius: 4px;
  p {
      height: 40px;
      line-height: 40px;
      margin: 10px;
      font-size: 14px;
  }
  label {
    color: #fa5151;
  }
  span {
    color: #07c160;
  }
}
</style>
