<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><a href="/overview">开放平台</a></el-breadcrumb-item>
            <el-breadcrumb-item>数据服务</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color: rgba(41, 100, 255, 1);">使用须知</span></el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">使用须知
            <p class="updateTime">更新时间：2022-10-14</p>
          </div>
          <p>1.使用本平台数据服务，需事先进行合作申请，发送个人使用需求给杨凌平台运营人员。</p>
          <p>2.所有用户在通过平台运营人员允许之后，获取使用本平台调用凭证，方可进行服务数据获取。</p>
          <p>3.本平台有小程序，微信公众号，后台运营管理网站三大服务平台。</p>
          <p>4.小程序，微信公众号兼容了uniapp，H5，wx等多种框架，语言。后台运营管理网站兼容了vue框架，element Ui组件，axios请求服务等。</p>
          <p class="img_title">合作申请全程导向</p>
          <el-image :src="require('@/assets/img/call_flow.png')" style="margin-left: -0.6%;margin-top: 3%;"></el-image>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>



<script>
export default {
  data() {
    return {
    }
  },
  created() {
    
  },
  methods: {

  }
}



</script>
<style scoped lang="less">
.title {
  margin-bottom: 3%;
}

.img_title {
    font-size: 20px;
    margin-top: 3%;
    margin-left: -21%;
    color: #000;
    font-weight: bold;
    text-align: center;
}

p {
  height: 40px;
  line-height: 40px;
}
</style>