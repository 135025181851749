<template>
  <el-container class="container">
     <el-header class="header">
       <el-row class="nav_horizon">
        <el-col class="head_title">杨凌农业人力资源共享服务平台</el-col>
        <el-col class="nav-box">
          <router-link to='/homeContent'>
            <div class="box" id="box1" ref="box1Sty" @click="changeNavPage('box1')">首页</div>
          </router-link>
          <router-link to='/openPlat'>
            <div class="box" id="box2" ref="box2Sty" @click="changeNavPage('box2')" style="color: #1968FC; font-weight: bolder; font-size: 20px;">开放平台</div>
          </router-link>
        </el-col>
        <el-col class="focus">
          <button type="primary" class="focus_button" @mouseover="onMouseOver" @mouseleave="onMouseOut">立即关注</button>
          <div class="focusCard" ref="focus">
             <el-image :src="require('@/assets/img/ylqr.jpg')" class="focusImg"></el-image>
             <p>关注&nbsp;&nbsp;“<span style="color: rgba(41, 100, 255, 1);font-weight: bolder;">新雨</span>”&nbsp;&nbsp;公众号</p>
             <p style="margin-top: -8%;">获取更多农业资讯</p>
          </div>
        </el-col>
      </el-row>
     </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer style="height: 300px;">
      <el-row>
        <el-col class="footerText">
           <p style="font-size: 20px;">联系我们</p>
           <p style="font-size: 20px;">杨凌农业人力资源共享服务平台</p>
           <p>咨询电话：029-989089887</p>
        </el-col>
      </el-row>
      <el-row>
         <p class="linkSty">友情链接：
           &nbsp;<a href="https://www.nongliankeji.cn/" target="_blank" style="color: #1E90FF;">农链科技</a>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="http://sl.china.com.cn/xczx/" target="_blank" style="color: #1E90FF;">乡村振兴</a>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="http://sl.china.com.cn/" target="_blank" style="color: #1E90FF;">丝路中国</a>
         </p>
      </el-row>
      <el-row class="footerImg">
         <el-image :src="require('@/assets/img/qyqr.png')"></el-image>
         <el-image :src="require('@/assets/img/ylrsqr.png')"></el-image>
         <p><span>秦云就业微信小程序</span><span>杨凌人社公众号</span></p>
      </el-row>
      <el-row class="footerBot">
        <p class="footerBotText"><a href="https://www.nongliankeji.cn/" target="_blank">nongliankeji.cn</a>  版权所有 备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">陕ICP备19000833号-1</a> 技术支持：<a href="https://www.nongliankeji.cn/" target="_blank">西安农链互联网科技有限公司</a></p>
      </el-row>
    </el-footer>
  </el-container>
</template>



<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    //切换导航页面
    changeNavPage(id){
      if(id == 'box1'){
        this.$refs.box1Sty.style.color='#1968FC';
        this.$refs.box2Sty.style.color='';
        this.$refs.box1Sty.style.fontWeight='bolder';
        this.$refs.box2Sty.style.fontWeight='';
        this.$refs.box1Sty.style.fontSize='20px';
        this.$refs.box2Sty.style.fontSize='';
      }else if(id == 'box2'){
        this.$refs.box1Sty.style.color='';
        this.$refs.box2Sty.style.color='#1968FC';
        this.$refs.box1Sty.style.fontWeight='';
        this.$refs.box2Sty.style.fontWeight='bolder';
        this.$refs.box1Sty.style.fontSize='';
        this.$refs.box2Sty.style.fontSize='20px';
      }
    },
    //鼠标移入
    onMouseOver(){
      this.$refs.focus.style.display = 'block';
    },
    // 鼠标移出
    onMouseOut(){
      this.$refs.focus.style.display = 'none';
    }
  }
}

</script>
<style scoped lang="less">
.container {
  .nav_horizon {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }
  
 .nav-box {
    display: flex;
    align-items: center;
    justify-content: center;
    top: -5.5%;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    margin-left: -33%;
    position: relative;
    background: #f5f8ff;
  }

  .box {
    width: 90px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    padding: 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    color: #000000;
    margin-left: -15.5%;
  }
  .el-header {
    background-color: #f5f8ff;
    color: #1968FC;
    text-align: left;
    line-height: 61px;
    z-index: 999;
  }

   .el-footer {
    background-color: #333333;
    color: #FFFFFF;
    text-align: left;
    line-height: 61px;
    .footerText {
       margin-left: 7%;
       margin-top: 2%;
     }

    .linkSty {
       margin-left: 7%;
       margin-top: 1%;
    }

    .footerImg {
       top: -86%;
       left: 66%;
       .el-image {
          margin-left: 3%;
          margin-bottom: -1%;
          width: 164px;
          height: 164px;
       }
       span {
         margin-left: 3.8%;
         padding-right: 1.3%;
       }
    }

    .footerBot {
      background: rgba(69, 69, 69, 1);
      width: 1873px;
      height: 60px;
      margin-top: -12.6%;
      margin-left: -1.07%;
      .footerBotText {
         height: 30px;
         text-align: center;
         opacity: 0.5;
         font-size: 14px;
         color:#C0C0C0;
         a:link {
           color:#C0C0C0;
         }
         a:visited, a:hover, a:active {
           color:#FFFFFF;
           font-size: 16px;
         }
      }
    }
  }
  
  .el-main {
    background-color: #FFFFFF;
    color: #000000;
    text-align: left;
    line-height: 40px;
    margin-top: 50px;
    overflow-x: hidden !important;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  

  .head_title {
    font-size: 20px;
    color: #1968FC;
    font-weight: bolder;
    width: 110%;
    background: #f5f8ff;
    height: 61px;
    line-height: 61px;
    text-align: left;
    padding-left: 30px;
  }
  .focus {
    width: 100%;
    background: #f5f8ff;
    height: 61px;
    line-height: 61px;
    text-align: center;
    padding-left: 30px;
    margin-right: -10%;
    .focus_button {
      font-weight: bolder;
      font-size: 15px;
      color: #FFFFFF;
      width: 180px;
      height: 50px;
      opacity: 1;
      border: none;
      background: rgba(25, 104, 252, 1);
      box-shadow: 0px 10px 40px 0px rgba(25, 104, 252, 0.3);
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .focusCard {
     margin-bottom: -19.5%;
     margin-top: 6%;
     margin-left: 37%;
     width: 196px;
     height: 257px;
     background: url("../assets/img/focus.png");
     opacity: 1;
     border-radius: 10px;
     background: rgba(255, 255, 255, 1);
     box-shadow: 0px 0px 11px 0px rgba(221, 224, 235, 1);
     display: none;
     .focusImg {
       margin-top: 3.5%;
       margin-left: 1%;
       width: 164px;
       height: 164px;
       opacity: 1;
       border-radius: 10px;
     }
     p {
        margin-left: 20.5%;
        margin-top: 5%;
        width: 126px;
        height: 41px;
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 0px;
        color: rgba(0, 0, 0, 1);
        text-align: center;
        vertical-align: top;
     }
  }

  .header {
    background: #fff;
    position: absolute;
    top: 0;
    left: -20px;
    right: 0;
    line-height: 80px;
    box-shadow: 0px 0px 20px 1px #eee;
    text-align: right;
    .icon {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .main {
    background: #FFFFFF;
    position: relative;
    padding-top: 100px;
  }
}
</style>